export default defineNuxtRouteMiddleware((to) => {
  const runtimeConfig = useRuntimeConfig()

  // Not handle non GLOBAL region
  if (isJobBoard()) {
    return
  }
  // No need to handle these pages
  if (
    to.fullPath.includes('hradmin') ||
    to.fullPath.includes('admin') ||
    to.fullPath.startsWith('onboard')
  ) {
    return
  }
  console.log('TLSkip middleware', to.fullPath)

  const { status: authStatus, data: user } = useAuth()
  const nuxt = useNuxtApp()

  // If the user is not authenticated, redirect to the TL login page
  if (!user.value || authStatus.value !== 'authenticated')
    return navigateTo(runtimeConfig.public.tlLoginUrl, { external: true })

  if (isSuperAdmin(user.value)) return navigateTo(nuxt.$localePath('admin'))
  else if (isHrAdmin(user.value)) return navigateTo(nuxt.$localePath('hradmin'))

  return navigateTo(runtimeConfig.public.tlLoginUrl, { external: true })
})
